import { AxiosInstance } from "axios";
import { TransitionObject } from "./Transition.types";

/**
 * API METHOD - creates a transition object on the server and sends a
 * mail to the new holder
 *
 * @param transitionObject the instance to create
 * @param axios the axios instance
 * @returns true if succesfull, false otherwise
 */
export const createTransitionObjectAndSendMail = async (
  transitionObject: TransitionObject,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/mail/transition/direct/", transitionObject)
    .then(
      (createdTransitionObjectResp) =>
        createdTransitionObjectResp.status === 200
    )
    .catch((error) => {
      console.error("Error during creating new transition object!", error);
      return false;
    });
};

/**
 * API method to transfer an item directly on DIS without a backend
 * transitionobject (which can happen when the QR code is scanned on the app)
 *
 * @param idNewOwner the user id of the new holder
 * @param idAsset the asset id of the transition item
 * @param axios the axios instance
 * @returns true if successfull, false otherwise
 */
export const acceptTransitionWithoutTransitionObject = async (
  transitionObject: TransitionObject,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/mail/transition/app/decision/", transitionObject)
    .then(
      (decidedTransitionResponse) => decidedTransitionResponse.status === 200
    )
    .catch((error) => {
      console.error("Error during updating transition object!", error);
      return false;
    });
};
