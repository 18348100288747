import {
  InputComponent,
  LayoutComponent,
  ButtonComponent,
  PopupComponent,
} from "daume-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import "../styles/NoteCreationStyles.scss";
import {
  createEmptyDisProtocolRequest,
  DisProtocolRequest,
} from "../utils/assets/Assets.types";
import { createNoteForAssetId } from "../utils/assets/AssetsUtils";
import { useAxios } from "../utils/AxiosUtil";
import { decryptString } from "../utils/crypt/CryptUtils";

interface NoteCreationPageProps {}

const NoteCreationPage: React.FC<NoteCreationPageProps> = () => {
  const location = useLocation<{ assetId: number }>();
  const history = useHistory();
  const { t } = useTranslation();
  const axios = useAxios();
  const [newProtocol, setNewProtocol] = useState<DisProtocolRequest>();
  const [assetId, setAssetId] = useState<number>(0);
  const currentUserId: number = parseInt(
    decryptString(
      localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_USER_ID!)!
    )
  );

  /**
   * creates the empty protocol for creation
   */
  useEffect(() => {
    if (location.state?.assetId) {
      setAssetId(location.state.assetId);
      setNewProtocol(
        createEmptyDisProtocolRequest(location.state.assetId, currentUserId)
      );
    }
    // eslint-disable-next-line
  }, [location]);

  /**
   * Helper to create a note on the server for the current asset
   */
  const createNoteOnServer = (): void => {
    createNoteForAssetId(axios, newProtocol!).then((success) => {
      if (success) history.push("/detail", { assetId: assetId });
      else {
        // TODO Notification
        console.error("note could not be created", newProtocol);
      }
    });
  };

  /**
   * this useEffect sets the correct growth height
   * for the current textarea
   */
  useEffect(() => {
    const inputElements: HTMLCollectionOf<Element> =
      document.getElementsByClassName("input-component");
    const inputElementsWrappers: HTMLCollectionOf<Element> =
      document.getElementsByClassName("input-component-wrapper");
    if (inputElements[0] && inputElementsWrappers[0]) {
      const maxHeight: number =
        (inputElementsWrappers[0] as HTMLDivElement).clientHeight - 20;
      const newHeight: number =
        (newProtocol?.TASK_NOTICE?.match(/[^\n]*\n[^\n]*/gi)?.length || 0) *
          13 +
        10;
      (inputElements[0] as HTMLDivElement).style.height = `${
        maxHeight > newHeight ? newHeight : maxHeight
      }px`;
    }
  }, [newProtocol?.TASK_NOTICE]);

  return (
    <LayoutComponent>
      <div className="note-creation--popup-wrapper">
        <PopupComponent
          closeFunction={() => history.push("/detail", { assetId: assetId })}
          showClose
        >
          {newProtocol && (
            <form
              className={"note-creation-page--wrapper"}
              onSubmit={(evt) => {
                evt.preventDefault();
                createNoteOnServer();
              }}
            >
              <div className="note-creation-page--wrapper-entry">
                <p className="title">{t("note.title")}</p>
                <InputComponent
                  required
                  type="multiline"
                  value={newProtocol.TASK_NOTICE}
                  onChange={(newValue) =>
                    setNewProtocol({ ...newProtocol, TASK_NOTICE: newValue })
                  }
                  placeholder={t("note.text")}
                />
              </div>
              <ButtonComponent title={t("general.buttons.save")} />
            </form>
          )}
        </PopupComponent>
      </div>
    </LayoutComponent>
  );
};

export default NoteCreationPage;
