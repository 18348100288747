import {
  FabActionProps,
  FabComponent,
  LayoutComponent,
  ListComponent,
  ListEntry,
  LoaderComponent,
  PopupComponent,
} from "daume-component-library";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as HandIcon } from "../assets/icons/hand-icon.svg";
import { ReactComponent as NoteIcon } from "../assets/icons/note-icon.svg";
import { ReactComponent as PenIcon } from "../assets/icons/pen-icon.svg";
import "../styles/AssetDetailStyles.scss";
import { useEffect, useState } from "react";
import {
  fetchAllNotesForAssetId,
  fetchAssetByIdWithAdditionalData,
  generateAssetDetailListEntries,
} from "../utils/assets/AssetsUtils";
import { useAxios } from "../utils/AxiosUtil";
import {
  Asset,
  AssetNote,
  createEmptyAsset,
} from "../utils/assets/Assets.types";
import { decryptString } from "../utils/crypt/CryptUtils";
import { isUserAdmin, isUserManager } from "../utils/user/UserUtils";

interface AssetDetailPageProps {}

const AssetDetailPage: React.FC<AssetDetailPageProps> = () => {
  const location = useLocation<{ assetId: number }>();
  const history = useHistory();
  const { t } = useTranslation();
  const [localAsset, setLocalAsset] = useState<Asset>(createEmptyAsset());
  const [localAssetDetailListEntries, setLocalAssetDetailListEntries] =
    useState<ListEntry[]>();
  const [localAssetNotes, setLocalAssetNotes] = useState<AssetNote[]>([]);
  const axios = useAxios();
  const [isLoading, toggleLoading] = useState<boolean>(true);
  const currentAddressId: number = parseInt(
    decryptString(
      localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_ADDRESS_ID!)!
    )
  );
  const isAdmin: boolean = isUserAdmin();
  const isManager: boolean = isUserManager();

  /**
   * set the initial detail info
   */
  useEffect(() => {
    if (!!location.state?.assetId && !!axios && !localAssetDetailListEntries)
      fetchAssetByIdWithAdditionalData(axios, location.state.assetId).then(
        (loadedAsset) => {
          setLocalAsset(loadedAsset);
          setLocalAssetDetailListEntries(
            generateAssetDetailListEntries(loadedAsset)
          );
          toggleLoading(false);
        }
      );
    // eslint-disable-next-line
  }, [axios, location]);

  /**
   * loads the notes for an asset by id
   */
  useEffect(() => {
    if (localAssetDetailListEntries && !!axios && !!localAsset.assetNo)
      fetchAllNotesForAssetId(axios, localAsset.assetNo).then((loadedNotes) =>
        setLocalAssetNotes(loadedNotes)
      );
  }, [axios, localAssetDetailListEntries, localAsset]);

  /**
   * Helper to generate only the needed fab entries
   *
   * @returns array of allowed actions
   */
  const generateFabEntries = (): FabActionProps[] => {
    let returnTarget: FabActionProps[] = [];
    // edit mode
    if (isAdmin || isManager)
      returnTarget.push({
        icon: <PenIcon />,
        onClick: () =>
          history.push("/edit", {
            assetId: location.state.assetId,
          }),
        text: t("general.buttons.edit"),
      });
    // transition or takeover
    returnTarget.push({
      icon: <HandIcon />,
      onClick: () =>
        history.push(
          localAsset.holderId === currentAddressId
            ? "/transition"
            : "/takeover",
          {
            assetId: location.state.assetId,
          }
        ),
      text:
        localAsset.holderId === currentAddressId
          ? t("general.transition")
          : t("general.takeover"),
    });
    // note creation
    returnTarget.push({
      icon: <NoteIcon />,
      onClick: () =>
        history.push("/note", {
          assetId: location.state.assetId,
        }),
      text: t("general.note"),
    });
    return returnTarget;
  };

  return (
    <LayoutComponent>
      <PopupComponent
        closeFunction={() => history.push("/dashboard")}
        showClose
      >
        <div className={"asset-detail-page--wrapper"}>
          {isLoading ? (
            <LoaderComponent />
          ) : (
            <>
              <ListComponent
                list={localAssetDetailListEntries || []}
                onClick={() => {}}
              />
              {localAssetNotes.map((note, noteIndex) => (
                <div
                  className="asset-note--wrapper"
                  key={`note-to-asset-${noteIndex}`}
                >
                  <div className="asset-note--content-title">
                    <div>{`Autor ${note.author || noteIndex + 1}`}</div>
                    <small>
                      {note.createDate.toLocaleString("de-DE", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      }) || "-"}
                    </small>
                  </div>
                  <div className="asset-note--content">{note.content}</div>
                </div>
              ))}
            </>
          )}
        </div>
        <FabComponent actions={generateFabEntries()} />
      </PopupComponent>
    </LayoutComponent>
  );
};

export default AssetDetailPage;
