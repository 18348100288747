import { InputType } from "./General";

/**
 * CONFIG for inputs which are needed for asset creation
 */
export const renderItemsForAssetCreation: {
  key: string;
  type: InputType;
  required?: boolean;
}[] = [
  { key: "inventoryNo", type: InputType.STRING, required: true },
  { key: "description", type: InputType.STRING, required: true },
  { key: "annotation", type: InputType.STRING },
  { key: "year", type: InputType.STRING },
  { key: "qsLock", type: InputType.BOOLEAN },
  { key: "destroyed", type: InputType.BOOLEAN },
];
