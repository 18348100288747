import {
  ButtonComponent,
  LayoutComponent,
  ListComponent,
  ListEntry,
  LoaderComponent,
  PopupComponent,
} from "daume-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import "../styles/AssetTransitionStyles.scss";
import { Asset } from "../utils/assets/Assets.types";
import {
  fetchAssetById,
  fetchHolderNameForAssetId,
} from "../utils/assets/AssetsUtils";
import { useAxios } from "../utils/AxiosUtil";
import {
  QrCodeType,
  TransitionObject,
  TransitionState,
} from "../utils/transition/Transition.types";
import { acceptTransitionWithoutTransitionObject } from "../utils/transition/TransitionUtils";
import { decryptString } from "../utils/crypt/CryptUtils";

interface AssetTransitionDecisionPageProps {}

const AssetTransitionDecisionPage: React.FC<
  AssetTransitionDecisionPageProps
> = () => {
  const location = useLocation<{
    transitionObject: TransitionObject;
    type: QrCodeType;
  }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const axios = useAxios();
  const [loadedTransitionObject, setLoadedTransitionObject] =
    useState<TransitionObject>();
  const [loadedTransitionType, setLoadedTransitionType] =
    useState<QrCodeType>();
  const [localLoadedAsset, setLocalLoadedAsset] = useState<Asset>();
  const [fetchedFromUserName, setFetchedFromUserName] = useState<string>("");
  const [fetchedToUserName, setFetchedToUserName] = useState<string>("");
  const [isLoadingDataFromServer, toggleLoadingDataFromServer] =
    useState<boolean>(true);
  const currentAddressId: number = parseInt(
    decryptString(
      localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_ADDRESS_ID!)!
    )
  );

  /**
   * sets local data for further working
   */
  useEffect(() => {
    if (location.state && !!axios) {
      setLoadedTransitionObject(location.state.transitionObject);
      setLoadedTransitionType(location.state.type);

      Promise.all([
        fetchAssetById(axios, location.state.transitionObject.assetId),
        location.state.type === QrCodeType.TRANSITION_OBJECT_TAKEOVER
          ? fetchHolderNameForAssetId(
              axios,
              location.state.transitionObject.addressIdTo
            )
          : undefined,
      ]).then(([loadedAssets, loadedToUserName]) => {
        setFetchedFromUserName(loadedAssets.holder);
        setFetchedToUserName(loadedToUserName || "");
        setLocalLoadedAsset(loadedAssets);
        toggleLoadingDataFromServer(false);
      });
    }
    // eslint-disable-next-line
  }, [location, axios]);

  /**
   * Helper to generate entries in list
   *
   * @returns entries for list
   */
  const generateListEnriesForTransitionObject = (): ListEntry[] => {
    return [
      {
        id: "1",
        label: t("transitionDecisionPage.assetName"),
        value: localLoadedAsset!.description,
      },
      {
        id: "2",
        label: t("transitionDecisionPage.from"),
        value: fetchedFromUserName,
      },
      {
        id: "3",
        label: t("transitionDecisionPage.to"),
        value:
          loadedTransitionType === QrCodeType.TRANSITION_OBJECT_TAKEOVER
            ? fetchedToUserName
            : t("transitionDecisionPage.me", {
                replace: {
                  name: decryptString(
                    localStorage.getItem(
                      process.env.REACT_APP_LOCALSTORAGE_DISPLAY_NAME!
                    )!
                  ),
                },
              }),
      },
    ];
  };

  /**
   * Helper to accept the current transition object directly on DIS
   */
  const sendAcceptOrDecline = (newState: TransitionState): void => {
    setIsLoading(true);
    if (!loadedTransitionObject) return; // TODO add notification
    let localTransitionObjectToWorkOn: TransitionObject =
      loadedTransitionObject;

    if (loadedTransitionType === QrCodeType.TRANSITION_OBJECT) {
      localTransitionObjectToWorkOn = {
        ...localTransitionObjectToWorkOn,
        addressIdTo: currentAddressId,
      };
    }
    acceptTransitionWithoutTransitionObject(
      {
        ...localTransitionObjectToWorkOn,
        appUserId: currentAddressId,
        state: newState,
      },
      axios
    ).then((successfullyAccepted) => {
      if (successfullyAccepted) {
        history.push("/dashboard");
      } else console.error("error during direct accept");
      setIsLoading(false);
    });
  };

  /**
   * Helper to determine if the user is allowed to decide
   * if this transition should be done
   *
   * @returns if user is allowed
   */
  const isUserAllowedToDecideTransition = (): boolean => {
    if (!loadedTransitionObject) return false;
    const isTransitionPending: boolean =
      loadedTransitionObject.state === TransitionState.PENDING;
    let additionalConditions: boolean = false;
    switch (loadedTransitionType) {
      case QrCodeType.TRANSITION_OBJECT:
        additionalConditions =
          loadedTransitionObject.addressIdTo === currentAddressId;
        break;
      case QrCodeType.TRANSITION_OBJECT_TAKEOVER:
        additionalConditions =
          loadedTransitionObject.addressIdFrom === currentAddressId;
        break;
    }
    // TODO remove after testing
    additionalConditions = true;
    return isTransitionPending && additionalConditions;
  };

  return (
    <LayoutComponent>
      <div className="asset-transition-decision-page--popup-wrapper">
        <PopupComponent
          closeFunction={() => history.push("/dashboard")}
          showClose
        >
          {isLoadingDataFromServer ? (
            <LoaderComponent />
          ) : (
            <div className={"asset-transition-decision-page--wrapper"}>
              <div className="asset-transition-decision-page--wrapper--text-wrapper">
                <ListComponent list={generateListEnriesForTransitionObject()} />
              </div>

              <div className="asset-transition-decision-page--wrapper--button-wrapper">
                {isUserAllowedToDecideTransition() ? (
                  <>
                    <ButtonComponent
                      isLoading={isLoading}
                      disabled={isLoading}
                      title={t("general.buttons.accept")}
                      type="button"
                      onClick={() =>
                        sendAcceptOrDecline(TransitionState.ACCEPTED)
                      }
                    />
                    <ButtonComponent
                      isLoading={isLoading}
                      disabled={isLoading}
                      title={t("general.buttons.decline")}
                      type="button"
                      onClick={() =>
                        sendAcceptOrDecline(TransitionState.DECLINED)
                      }
                    />
                  </>
                ) : (
                  <ButtonComponent
                    title={t("general.buttons.cancel")}
                    type="button"
                    onClick={() => history.push("/dashboard")}
                  />
                )}
              </div>
            </div>
          )}
        </PopupComponent>
      </div>
    </LayoutComponent>
  );
};

export default AssetTransitionDecisionPage;
