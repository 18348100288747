/**
 * Enum which describes the type of input for map method
 */
export enum InputType {
  STRING,
  NUMBER,
  BOOLEAN,
}

/**
 * Helper to convert string into number
 * @param value data to convert
 * @returns found number or 0
 * @tested
 */
export const convertStringToInt = (value: string | null): number => {
  if (value === null) return 0;
  const parsedInt: number = parseInt(value.trim());
  if (isNaN(parsedInt)) return 0;
  return parsedInt;
};
