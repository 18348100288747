import { AxiosInstance } from "axios";
import { Option } from "daume-component-library";
import { decryptString, encryptString } from "../crypt/CryptUtils";
import { Address, createEmptyAddress, DisManager } from "./User.types";
import { History } from "history";
import { convertStringToInt } from "../General";

/**
 * API METHOD - fetches all users from DIS
 *
 * @param axios network instance
 * @param allUsers context variable
 * @returns loaded users
 */
export const fetchAllUsers = async (
  axios: AxiosInstance,
  allUsers?: Address[]
): Promise<Address[]> => {
  if (allUsers?.length !== 0) return allUsers!;
  return axios
    .get("/mail/address/all/")
    .then((loadedAddresses) => loadedAddresses.data)
    .catch((error) => {
      console.error("Error during fetching all users from DIS!", error);
      return [];
    });
};

/**
 * API METHOD - fetches needed data for a manager
 * @param axios network instance
 * @param userId id to fetch data for
 * @returns loaded manager info
 */
export const fetchManagerData = async (
  axios: AxiosInstance,
  userId: number,
  base64: string
): Promise<DisManager[]> => {
  return axios
    .get("/mail/address/manager/", {
      params: { userId: userId },
      headers: {
        Authorization: base64 ? `Basic ${base64}` : "",
      },
    })
    .then((loadedManager) => loadedManager.data)
    .catch((error) => {
      console.error("Error during fetching manager data from DIS!", error);
      return [];
    });
};

/**
 * API METHOD - for validating an user login
 *
 * @param axios
 * @param username
 * @param password
 * @returns if user login success or null on error
 */
export const validateLogin = async (
  axios: AxiosInstance,
  username: string,
  password: string
): Promise<Address> => {
  return axios
    .post("/mail/address/login/", btoa(`${username}:${password}`))
    .then((loginResp) => loginResp.data)
    .catch((error) => {
      console.error("Error during login validation!", error);
      return null;
    });
};

/**
 * Helper to determine the correct
 *
 * @returns found holder id
 * @tested
 */
export const currentHolderId = (
  addresses: Address[],
  holderId?: number
): string => {
  return holderId
    ? checkIfAddressIdIsPresent(addresses, holderId)
      ? holderId.toString()
      : ""
    : "";
};

/**
 * Helper to generate a list of options for all found users,
 * but the logged in user is disabled
 *
 * @param loggedInUserId
 * @returns List of options of users
 * @tested
 */
export const createUserOptions = (
  userList: Address[],
  mailAddressNeeded?: boolean
): Option[] => {
  let generatedOptionList: Option[] = [];
  userList.forEach((user) => {
    generatedOptionList.push({
      label: `${user.ADDRESS_DISPLAY_NAME} (${user.ADDRESS_EMAIL})`,
      value: user.ADDRESS_ID.toString(),
      disabled: mailAddressNeeded && !user.ADDRESS_EMAIL,
    });
  });
  return generatedOptionList;
};

/**
 * Helper to check if user id is present
 *
 * @param userList
 * @param currentId
 * @returns boolean if address id is present in loaded list
 * @tested
 */
export const checkIfAddressIdIsPresent = (
  userList: Address[],
  currentId: number
): boolean => {
  return userList.filter((user) => user.ADDRESS_ID === currentId).length === 1;
};

/**
 * Helper to extract username from loaded user list
 *
 * @param userId
 * @param userList
 * @returns found username or "unknown"
 */
export const getUsernameFromAddressId = (
  userId: number,
  userList: Address[]
): string => {
  let foundIndex: number = userList.findIndex(
    (user) => user.ADDRESS_ID === userId
  );
  if (foundIndex === -1) return "unknown";
  return userList[foundIndex].ADDRESS_DISPLAY_NAME;
};

/**
 * Helper to extract user from loaded user list
 *
 * @param userId
 * @param userList
 * @returns found Address
 * @tested
 */
export const getUserFromAddressId = (
  userId: number,
  userList: Address[]
): Address => {
  let foundIndex: number = userList.findIndex(
    (user) => user.ADDRESS_ID === userId
  );
  if (foundIndex === -1) return createEmptyAddress();
  return userList[foundIndex];
};

/**
 * Helper to store user data in localstorage
 *
 * @param user user data from DIS
 * @param base64 entered
 */
export const saveUserToLocalStorage = (
  user: Address,
  base64: string,
  manager: DisManager[]
): void => {
  localStorage.clear();
  localStorage.setItem(
    process.env.REACT_APP_LOCALSTORAGE_MANAGER!,
    encryptString(manager.length.toString())
  );
  localStorage.setItem(
    process.env.REACT_APP_LOCALSTORAGE_USER_ROLES!,
    encryptString(user.USER_ROLES.toString())
  );
  localStorage.setItem(
    process.env.REACT_APP_LOCALSTORAGE_BASE64!,
    encryptString(base64)
  );
  localStorage.setItem(
    process.env.REACT_APP_LOCALSTORAGE_ADDRESS_ID!,
    encryptString(user.ADDRESS_ID.toString())
  );
  localStorage.setItem(
    process.env.REACT_APP_LOCALSTORAGE_USER_ID!,
    encryptString(user.USER_ID.toString())
  );
  localStorage.setItem(
    process.env.REACT_APP_LOCALSTORAGE_PERSONNEL_NO!,
    encryptString(user.PERSONNEL_NO.toString())
  );
  localStorage.setItem(
    process.env.REACT_APP_LOCALSTORAGE_ADDRESS_MAIL!,
    encryptString(user.ADDRESS_EMAIL)
  );
  localStorage.setItem(
    process.env.REACT_APP_LOCALSTORAGE_DISPLAY_NAME!,
    encryptString(user.ADDRESS_DISPLAY_NAME)
  );
  localStorage.setItem(
    process.env.REACT_APP_LOCALSTORAGE_VALID_UNTIL!,
    new Date().toISOString().split("T")[0]
  );
};

/**
 * Helper to clear local storage and redirect to login
 *
 * @param history router object
 */
export const logoutUser = (history: History): void => {
  localStorage.clear();
  history.push("/login");
};

/**
 * Helper to determine if the current user is an app admin
 * @returns if user is app admin
 */
export const isUserAdmin = (): boolean => {
  const loadedEncrypt: string | null = localStorage.getItem(
    process.env.REACT_APP_LOCALSTORAGE_USER_ROLES!
  );
  if (loadedEncrypt === null) return false;
  const splittedRoles: string[] = decryptString(loadedEncrypt).split(",");
  return splittedRoles.includes("141.0000000000");
};

/**
 * Helper to determine if the current user is a manager
 * @returns if user is manager
 */
export const isUserManager = (): boolean => {
  const loadedEncrypt: string | null = localStorage.getItem(
    process.env.REACT_APP_LOCALSTORAGE_MANAGER!
  );
  if (loadedEncrypt === null) return false;
  const decrypted: string = decryptString(loadedEncrypt);
  return convertStringToInt(decrypted) !== 0;
};
