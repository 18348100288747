import { BrowserRouter, Switch, Route } from "react-router-dom";
import AssetDetailPage from "./AssetDetailPage";
import AssetEditPage from "./AssetEditPage";
import DashboardPage from "./DashboardPage";
import LoginPage from "./LoginPage";
import SplashScreen from "./SplashPage";
import "../styles/index.scss";
import AssetTransitionPage from "./AssetTransitionPage";
import AssetTransitionMailPage from "./AssetTransitionMailPage";
import AssetTransitionDecisionPage from "./AssetTransitionDecisionPage";
import PrivateRoute from "../utils/PrivateRoute";
import NoteCreationPage from "./NoteCreationPage";
import AssetTakeoverPage from "./AssetTakeoverPage";
import AssetCreatePage from "./AssetCreatePage";
import withClearCache from "../ClearCache";
import { createContext, useState } from "react";
import { Address } from "../utils/user/User.types";
import { DisLocation } from "../utils/location/Location.types";
import { AssetType, Model } from "../utils/assets/Assets.types";

/**
 * this context contains all needed serch settings which can be set
 */
export const SearchContext = createContext<{
  modelSearchString: string;
  setModelSearchString(modelSearchString: string): void;
  typeSearchString: string;
  setTypeSearchString(typeSearchString: string): void;
  searchString: string;
  setSearchString(searchString: string): void;
  locationSearchString: string;
  setLocationSearchString(locationSearchString: string): void;
  holderSearchString: string;
  setHolderSearchString(holderSearchString: string): void;
  isOverdue: boolean;
  toggleOverdue(isOverdue: boolean): void;
  isDueInTwoWeeks: boolean;
  toggleDueInTwoWeeks(isDueInTwoWeeks: boolean): void;
  dateSearchDate?: Date;
  setDateSearchDate(dateSearchDate: Date | undefined): void;
  isFilterOwnAssets: boolean;
  toggleFilterOwnAssets(isFilterOwnAssets: boolean): void;
}>({
  modelSearchString: "",
  setModelSearchString: () => {},
  typeSearchString: "",
  setTypeSearchString: () => {},
  searchString: "",
  setSearchString: () => {},
  locationSearchString: "",
  setLocationSearchString: () => {},
  holderSearchString: "",
  setHolderSearchString: () => {},
  isDueInTwoWeeks: false,
  toggleDueInTwoWeeks: () => {},
  isOverdue: false,
  toggleOverdue: () => {},
  setDateSearchDate: () => {},
  isFilterOwnAssets: false,
  toggleFilterOwnAssets: () => {},
});

/**
 * This context holds all fetched dynamic data from dis
 * it should increase the loadig performance, especially
 * for user fetching
 */
export const DisDataContext = createContext<{
  allUsers?: Address[];
  setAllUsers(allUsers: Address[]): void;
  allLocations?: DisLocation[];
  setAllLocations(allLocations: DisLocation[]): void;
  allModels?: Model[];
  setAllModels(allModels: Model[]): void;
  allAssetTypes?: AssetType[];
  setAllAssetTypes(allAssetTypes: AssetType[]): void;
}>({
  setAllUsers: () => {},
  setAllLocations: () => {},
  setAllModels: () => {},
  setAllAssetTypes: () => {},
});

const App = () => {
  const [refresh, toggleRefresh] = useState<boolean>(false);
  // DisData States
  const [localAllUsers, setLocalAllUsers] = useState<Address[]>([]);
  const [localAllLocations, setLocalAllLocations] = useState<DisLocation[]>([]);
  const [localAllModels, setLocalAllModels] = useState<Model[]>([]);
  const [localAllAssetTypes, setLocalAllAssetTypes] = useState<AssetType[]>([]);
  // Search States
  const [searchString, setSearchString] = useState<string>("");
  const [typeSearchString, setTypeSearchString] = useState<string>("");
  const [modelSearchString, setModelSearchString] = useState<string>("");
  const [locationSearchString, setLocationSearchString] = useState<string>("");
  const [holderSearchString, setHolderSearchString] = useState<string>("");
  const [isOverdue, toggleOverdue] = useState<boolean>(false);
  const [isDueInTwoWeeks, toggleDueInTwoWeeks] = useState<boolean>(false);
  const [dateSearchDate, setDateSearchDate] = useState<Date | undefined>();
  const [isFilterOwnAssets, toggleFilterOwnAssets] = useState<boolean>(false);

  return (
    <SearchContext.Provider
      value={{
        isDueInTwoWeeks: isDueInTwoWeeks,
        isOverdue: isOverdue,
        holderSearchString: holderSearchString,
        locationSearchString: locationSearchString,
        modelSearchString: modelSearchString,
        searchString: searchString,
        typeSearchString: typeSearchString,
        dateSearchDate: dateSearchDate,
        isFilterOwnAssets: isFilterOwnAssets,
        setModelSearchString: setModelSearchString,
        setSearchString: setSearchString,
        setTypeSearchString: setTypeSearchString,
        setHolderSearchString: setHolderSearchString,
        setLocationSearchString: setLocationSearchString,
        toggleDueInTwoWeeks: toggleDueInTwoWeeks,
        toggleOverdue: toggleOverdue,
        setDateSearchDate: setDateSearchDate,
        toggleFilterOwnAssets: toggleFilterOwnAssets,
      }}
    >
      <DisDataContext.Provider
        value={{
          setAllAssetTypes: setLocalAllAssetTypes,
          setAllLocations: setLocalAllLocations,
          setAllModels: setLocalAllModels,
          setAllUsers: setLocalAllUsers,
          allAssetTypes: localAllAssetTypes,
          allLocations: localAllLocations,
          allModels: localAllModels,
          allUsers: localAllUsers,
        }}
      >
        <BrowserRouter forceRefresh={refresh}>
          <Switch>
            <Route path="*">
              <SplashScreen />
              <Route path="/login" exact>
                <LoginPage toggleRefresh={toggleRefresh} />
              </Route>
              <PrivateRoute Page={DashboardPage} path="/dashboard" />
              <PrivateRoute Page={AssetDetailPage} path="/detail" />
              <PrivateRoute Page={AssetEditPage} path="/edit" />
              <PrivateRoute Page={AssetCreatePage} path="/create" />
              <PrivateRoute Page={NoteCreationPage} path="/note" />
              <PrivateRoute Page={AssetTransitionPage} path="/transition" />
              <PrivateRoute Page={AssetTakeoverPage} path="/takeover" />
              <PrivateRoute
                Page={AssetTransitionMailPage}
                path="/transition/mail"
              />
              <PrivateRoute
                Page={AssetTransitionDecisionPage}
                path="/transition/decision"
              />
            </Route>
          </Switch>
        </BrowserRouter>
      </DisDataContext.Provider>
    </SearchContext.Provider>
  );
};

const ClearCacheComponent = withClearCache(App);

export default ClearCacheComponent;
