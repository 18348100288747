import { IdleComponent } from "daume-component-library";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { isLoginStillValid } from "../utils/crypt/CryptUtils";

interface SplashScreenProperties {}

const SplashScreen: React.FC<SplashScreenProperties> = () => {
  const history = useHistory();
  const location = useLocation();

  /**
   * When the selected page is root show the splashscreen and
   * forward to the login after 1500ms
   */
  useEffect(() => {
    if (location.pathname === "/")
      setTimeout(() => {
        isLoginStillValid()
          ? history.push("/dashboard")
          : history.push("/login");
      }, 1500);
    // eslint-disable-next-line
  }, []);

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="splashscreen" timeout={300}>
        <Switch location={location}>
          <Route path="/" exact>
            <IdleComponent fullScreen />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default SplashScreen;
