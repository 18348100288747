import CryptoJS from "crypto-js";

/**
 * Helper to encrypt a plain string to a secure string
 *
 * @param plain message
 * @returns encrypted string
 */
export const encryptString = (plain: string): string => {
  return CryptoJS.AES.encrypt(
    plain,
    process.env.REACT_APP_LOCALSTORAGE_CIPHER_KEY!
  ).toString();
};

/**
 * Helper to decrypt an encrypted string
 *
 * @param encrpyted secure data
 * @returns plain string
 */
export const decryptString = (encrpyted: string): string => {
  const encryptedBytes = CryptoJS.AES.decrypt(
    encrpyted,
    process.env.REACT_APP_LOCALSTORAGE_CIPHER_KEY!
  );
  return encryptedBytes.toString(CryptoJS.enc.Utf8);
};

/**
 * Helper to determine if the login date is still valid (today)
 * and if a base64 string is present or clear the localstorage
 *
 * @returns if the date is today
 */
export const isLoginStillValid = (): boolean => {
  const base64 = localStorage.getItem(
    process.env.REACT_APP_LOCALSTORAGE_BASE64!
  );
  const validUntil = localStorage.getItem(
    process.env.REACT_APP_LOCALSTORAGE_VALID_UNTIL!
  );
  if (new Date().toISOString().split("T")[0] === validUntil && !!base64)
    return true;
  localStorage.clear();
  return false;
};
