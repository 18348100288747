import { useState, useEffect } from "react";
import axios, { AxiosInstance } from "axios";
import { decryptString } from "./crypt/CryptUtils";

/**
 * Creates a prefconfigured axios wrapper that also talks with
 * the keycloak instance.
 */
export const useAxios = () => {
  const [axiosInstance, setAxiosInstance] = useState({});
  const baseURL = process.env.REACT_APP_SERVICE_URL;
  const base64 = localStorage.getItem(
    process.env.REACT_APP_LOCALSTORAGE_BASE64!
  );

  useEffect(() => {
    const instance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: base64 ? `Basic ${decryptString(base64)}` : "",
      },
    });

    setAxiosInstance({ instance });

    return () => {
      setAxiosInstance({});
    };
    // eslint-disable-next-line
  }, [baseURL]);

  return (axiosInstance as any).instance as AxiosInstance;
};
