import {
  InputComponent,
  LayoutComponent,
  ButtonComponent,
  PopupComponent,
  Option,
  DropdownComponent,
  CheckboxComponent,
  LoaderComponent,
} from "daume-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "../styles/AssetEditStyles.scss";
import { Asset, createEmptyAsset } from "../utils/assets/Assets.types";
import {
  createAssetOnDis,
  createModelOptions,
  createTypeOptions,
  fetchAllAssetTypes,
  fetchAllModel,
} from "../utils/assets/AssetsUtils";
import { useAxios } from "../utils/AxiosUtil";
import { renderItemsForAssetCreation } from "../utils/ConfigUtil";
import { InputType } from "../utils/General";
import { createUserOptions, fetchAllUsers } from "../utils/user/UserUtils";
import { DisDataContext } from "./App";

interface AssetCreatePageProps {}

const AssetCreatePage: React.FC<AssetCreatePageProps> = () => {
  const {
    setAllAssetTypes,
    setAllModels,
    setAllUsers,
    allAssetTypes,
    allModels,
    allUsers,
  } = useContext(DisDataContext);
  const [assetToEdit, setAssetToEdit] = useState<Asset>(createEmptyAsset());
  const history = useHistory();
  const { t } = useTranslation();
  const axios = useAxios();
  const [localUserOption, setLocalUserOption] = useState<Option[]>([]);
  const [localModelOption, setLocalModelOption] = useState<Option[]>([]);
  const [localTypeOption, setLocalTypeOption] = useState<Option[]>([]);

  /**
   * fetches the correct asset from all asset list from context
   */
  useEffect(() => {
    if (
      localModelOption.length === 0 &&
      localUserOption.length === 0 &&
      localTypeOption.length === 0 &&
      !!axios
    )
      Promise.all([
        fetchAllModel(axios, allModels),
        fetchAllUsers(axios, allUsers),
        fetchAllAssetTypes(axios, allAssetTypes),
      ]).then(([models, loadedUsers, loadedAssetTypes]) => {
        setAllModels(models);
        setAllUsers(loadedUsers);
        setAllAssetTypes(loadedAssetTypes);

        setLocalModelOption(createModelOptions(models));
        setLocalUserOption(createUserOptions(loadedUsers));
        setLocalTypeOption(createTypeOptions(loadedAssetTypes));
      });
    // eslint-disable-next-line
  }, [axios]);

  return (
    <LayoutComponent>
      <div className="asset-edit-page--popup-wrapper">
        <PopupComponent
          closeFunction={() => history.push("/dashboard")}
          showClose
        >
          <form
            className={"asset-edit-page--wrapper"}
            onSubmit={(evt) => {
              evt.preventDefault();
              createAssetOnDis(axios, assetToEdit).then((success) => {
                if (success) {
                  history.push("/dashboard");
                } else {
                  // TODO NOTIFICATION
                }
              });
            }}
          >
            {renderItemsForAssetCreation.map((item) => (
              <div
                key={`edit-page-input-${item.key}`}
                className="asset-edit-page--wrapper-entry"
              >
                {(item.type === InputType.STRING ||
                  item.type === InputType.NUMBER) && (
                  <>
                    <p>{t(`asset.${item.key}`)}</p>
                    <InputComponent
                      required={item.required}
                      type={item.type === InputType.NUMBER ? "number" : "text"}
                      value={(assetToEdit[item.key] as string) || ""}
                      onChange={(newValue) =>
                        setAssetToEdit({
                          ...assetToEdit,
                          [item.key]:
                            item.type === InputType.NUMBER
                              ? Number(newValue)
                              : newValue,
                        })
                      }
                      placeholder={t(`asset.${item.key}`)}
                    />
                  </>
                )}
                {item.type === InputType.BOOLEAN && (
                  <CheckboxComponent
                    checked={assetToEdit[item.key] as boolean}
                    onCheck={() =>
                      setAssetToEdit({
                        ...assetToEdit,
                        [item.key]: !(assetToEdit[item.key] as boolean),
                      })
                    }
                    value={t(`asset.${item.key}`)}
                  />
                )}
              </div>
            ))}
            <div className="asset-edit-page--wrapper-entry">
              <CheckboxComponent
                checked={assetToEdit.testTypeId === 1}
                onCheck={() =>
                  setAssetToEdit({
                    ...assetToEdit,
                    testTypeId: assetToEdit.testTypeId === 1 ? 2 : 1,
                  })
                }
                value={t(`asset.testTypeId`)}
              />
            </div>

            <div className="asset-edit-page--wrapper-entry">
              {assetToEdit &&
              localUserOption.length > 0 &&
              localModelOption.length > 0 &&
              localTypeOption.length > 0 ? (
                <>
                  <p>{t(`asset.holderId`)}</p>
                  <DropdownComponent
                    required
                    searchable
                    selectedOption={
                      localUserOption.filter(
                        (userOption) =>
                          userOption.value === assetToEdit.holderId.toString()
                      )[0]?.value || ""
                    }
                    placeholder={t("asset.holderId")}
                    options={localUserOption}
                    onChange={(value) =>
                      setAssetToEdit({
                        ...assetToEdit,
                        holderId: Number(value),
                      })
                    }
                  />
                  <p>{t(`asset.modelId`)}</p>
                  <DropdownComponent
                    required
                    searchable
                    selectedOption={
                      localModelOption.filter(
                        (userOption) =>
                          userOption.value === assetToEdit.modelId.toString()
                      )[0]?.value || ""
                    }
                    placeholder={t("asset.modelId")}
                    options={localModelOption}
                    onChange={(value) =>
                      setAssetToEdit({
                        ...assetToEdit,
                        modelId: Number(value),
                      })
                    }
                  />
                  <p>{t(`asset.typeId`)}</p>
                  <DropdownComponent
                    required
                    searchable
                    selectedOption={
                      localTypeOption.filter(
                        (userOption) =>
                          userOption.value === assetToEdit.typeId.toString()
                      )[0]?.value || ""
                    }
                    placeholder={t("asset.typeId")}
                    options={localTypeOption}
                    onChange={(value) =>
                      setAssetToEdit({
                        ...assetToEdit,
                        typeId: Number(value),
                      })
                    }
                  />
                </>
              ) : (
                <LoaderComponent />
              )}
            </div>
            <ButtonComponent title={t("general.buttons.save")} />
          </form>
        </PopupComponent>
      </div>
    </LayoutComponent>
  );
};

export default AssetCreatePage;
