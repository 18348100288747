import {
  InputComponent,
  LayoutComponent,
  ButtonComponent,
  PopupComponent,
  Option,
  DropdownComponent,
  CheckboxComponent,
  LoaderComponent,
} from "daume-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import "../styles/AssetEditStyles.scss";
import { Asset, createEmptyAsset } from "../utils/assets/Assets.types";
import { fetchAssetById, updateAsset } from "../utils/assets/AssetsUtils";
import { useAxios } from "../utils/AxiosUtil";
import { InputType } from "../utils/General";
import { Address } from "../utils/user/User.types";
import {
  createUserOptions,
  currentHolderId,
  fetchAllUsers,
  isUserAdmin,
} from "../utils/user/UserUtils";
import { DisDataContext } from "./App";

interface AssetEditPageProps {}

const AssetEditPage: React.FC<AssetEditPageProps> = () => {
  const { setAllUsers, allUsers } = useContext(DisDataContext);
  const location = useLocation<{ assetId: number }>();
  const [assetToEdit, setAssetToEdit] = useState<Asset>(createEmptyAsset());
  const history = useHistory();
  const { t } = useTranslation();
  const axios = useAxios();
  const [localUserOption, setLocalUserOption] = useState<Option[]>([]);
  const [localLoadedUsers, setLocalLoadedUsers] = useState<Address[]>([]);
  const isAdmin: boolean = isUserAdmin();

  /**
   * fetches the correct asset from all asset list from context
   */
  useEffect(() => {
    if (location.state?.assetId && !!axios)
      fetchAssetById(axios, location.state.assetId).then((loadedAsset) =>
        setAssetToEdit(loadedAsset)
      );
    if (localUserOption.length === 0 && !!axios)
      fetchAllUsers(axios, allUsers).then((loadedUsers) => {
        setAllUsers(loadedUsers);
        setLocalLoadedUsers(loadedUsers);
        setLocalUserOption(createUserOptions(loadedUsers));
      });
    // eslint-disable-next-line
  }, [location, axios]);

  /**
   * Helper to determine which data an admin/manager should see
   */
  const renderItems: { key: string; type: InputType }[] = isAdmin
    ? [
        { key: "inventoryNo", type: InputType.STRING },
        { key: "description", type: InputType.STRING },
        { key: "note", type: InputType.STRING },
        { key: "annotation", type: InputType.STRING },
        { key: "year", type: InputType.NUMBER },
        { key: "qsLock", type: InputType.BOOLEAN },
        { key: "destroyed", type: InputType.BOOLEAN },
      ]
    : [
        { key: "note", type: InputType.STRING },
        { key: "annotation", type: InputType.STRING },
        { key: "qsLock", type: InputType.BOOLEAN },
        { key: "destroyed", type: InputType.BOOLEAN },
      ];

  /**
   * Helper to generate correct component for specific input with type and key
   *
   * @param item
   * @returns generated component for input type
   */
  const generateCorrectRenderComponent = (item: {
    key: string;
    type: InputType;
  }): JSX.Element => {
    return (
      <div
        key={`edit-page-input-${item.key}`}
        className="asset-edit-page--wrapper-entry"
      >
        {(item.type === InputType.STRING || item.type === InputType.NUMBER) && (
          <>
            <p>{t(`asset.${item.key}`)}</p>
            <InputComponent
              type={item.type === InputType.NUMBER ? "number" : "text"}
              value={(assetToEdit[item.key] as string) || ""}
              onChange={(newValue) =>
                setAssetToEdit({
                  ...assetToEdit,
                  [item.key]:
                    item.type === InputType.NUMBER
                      ? Number(newValue)
                      : newValue,
                })
              }
              placeholder={t(`asset.${item.key}`)}
            />
          </>
        )}
        {item.type === InputType.BOOLEAN && (
          <CheckboxComponent
            checked={assetToEdit[item.key] as boolean}
            onCheck={() =>
              setAssetToEdit({
                ...assetToEdit,
                [item.key]: !(assetToEdit[item.key] as boolean),
              })
            }
            value={t(`asset.${item.key}`)}
          />
        )}
      </div>
    );
  };

  return (
    <LayoutComponent>
      <div className="asset-edit-page--popup-wrapper">
        <PopupComponent
          closeFunction={() => history.push("/dashboard")}
          showClose
        >
          <form
            className={"asset-edit-page--wrapper"}
            onSubmit={(evt) => {
              evt.preventDefault();
              updateAsset(axios, assetToEdit).then((success) => {
                // TODO add notification if success is false
                if (success) history.push("/dashboard");
              });
            }}
          >
            {renderItems.map((item) => generateCorrectRenderComponent(item))}
            <div className="asset-edit-page--wrapper-entry">
              {localUserOption.length > 0 ? (
                <>
                  <p>{t(`asset.holderId`)}</p>
                  <DropdownComponent
                    searchable
                    selectedOption={currentHolderId(
                      localLoadedUsers,
                      assetToEdit.holderId
                    )}
                    placeholder={t("asset.holderId")}
                    options={localUserOption}
                    onChange={(value) =>
                      setAssetToEdit({
                        ...assetToEdit,
                        holderId: Number(value),
                      })
                    }
                  />
                </>
              ) : (
                <LoaderComponent />
              )}
            </div>
            {localUserOption.length === 0 || (
              <ButtonComponent
                disabled={localUserOption.length === 0}
                title={t("general.buttons.save")}
              />
            )}
          </form>
        </PopupComponent>
      </div>
    </LayoutComponent>
  );
};

export default AssetEditPage;
