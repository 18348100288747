import {
  LayoutComponent,
  InputComponent,
  ButtonComponent,
} from "daume-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "../styles/LoginPageStyles.scss";
import { useAxios } from "../utils/AxiosUtil";
import {
  fetchManagerData,
  saveUserToLocalStorage,
  validateLogin,
} from "../utils/user/UserUtils";

interface LoginPageProps {
  toggleRefresh(refresh: boolean): void;
}

const LoginPage: React.FC<LoginPageProps> = ({ toggleRefresh }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const axios = useAxios();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [showError, toggleError] = useState<boolean>(false);

  /**
   * Helper to perform the login against the server
   */
  const performLogin = (): void => {
    toggleLoading(true);
    validateLogin(axios, username, password).then((loadedUser) => {
      if (loadedUser) {
        const generatedBase64: string = btoa(`${username}:${password}`);
        // validate manager position too
        fetchManagerData(axios, loadedUser.USER_ID, generatedBase64).then(
          (managerData) => {
            saveUserToLocalStorage(loadedUser, generatedBase64, managerData);
            toggleRefresh(true);
            history.push("/dashboard");
          }
        );
      } else {
        toggleError(true);
        toggleLoading(false);
      }
    });
  };

  return (
    <LayoutComponent idle>
      <div className={"login-page--wrapper"}>
        <h1>{t("loginPage.welcome")}</h1>
        <small>{t("loginPage.signInToContinue")}</small>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            performLogin();
          }}
        >
          <div className={"login-page--input-wrapper"}>
            <InputComponent
              value={username}
              onChange={setUsername}
              placeholder={t("loginPage.username")}
            />
            <InputComponent
              value={password}
              type="password"
              onChange={setPassword}
              placeholder={t("loginPage.password")}
            />
          </div>
          {showError && (
            <p className="wrong-credentials">{t("loginPage.wrongAuth")}</p>
          )}
          <ButtonComponent isLoading={isLoading} title={t("loginPage.login")} />
        </form>
        <div className={"login-page--wrapper--version"}>
          v{t("general.version")}
        </div>
      </div>
    </LayoutComponent>
  );
};

export default LoginPage;
