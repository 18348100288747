import {
  ButtonComponent,
  LayoutComponent,
  LoaderComponent,
  PopupComponent,
} from "daume-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useHistory, useLocation } from "react-router-dom";
import "../styles/AssetTransitionStyles.scss";
import { Asset, createEmptyAsset } from "../utils/assets/Assets.types";
import { fetchAssetById } from "../utils/assets/AssetsUtils";
import { useAxios } from "../utils/AxiosUtil";
import {
  createEmptyTransitionObject,
  QrCodeType,
  TransitionObject,
} from "../utils/transition/Transition.types";

interface AssetTransitionPageProps {}

const AssetTransitionPage: React.FC<AssetTransitionPageProps> = () => {
  const location = useLocation<{ assetId: number }>();
  const [assetToEdit, setAssetToEdit] = useState<Asset>(createEmptyAsset());
  const [transitionObjectToSend, setTransitionObjectToSend] =
    useState<TransitionObject>();
  const history = useHistory();
  const { t } = useTranslation();
  const axios = useAxios();

  /**
   * sets local data for further working
   */
  useEffect(() => {
    if (location.state?.assetId && !!axios) {
      fetchAssetById(axios, location.state.assetId).then((loadedAsset) => {
        setAssetToEdit(loadedAsset);
        setTransitionObjectToSend({
          ...createEmptyTransitionObject(),
          assetId: location.state.assetId,
          addressIdFrom: loadedAsset.holderId,
        });
      });
    }
    // eslint-disable-next-line
  }, [location, axios]);

  /**
   * Redirects to the mail sending page with the correct asset
   */
  const createAndContinueTransition = (): void => {
    history.push("/transition/mail", {
      transitionObject: transitionObjectToSend,
    });
  };

  return (
    <LayoutComponent>
      <div className="asset-transition-page--popup-wrapper">
        <PopupComponent
          closeFunction={() => history.push("/dashboard")}
          showClose
        >
          {transitionObjectToSend ? (
            <div className={"asset-transition-page--wrapper"}>
              <div className="asset-transition-page--wrapper--text-wrapper">
                <p>
                  {t("transitionPage.assetText", {
                    replace: { assetLabel: assetToEdit.description },
                  })}
                </p>

                <QRCode
                  value={JSON.stringify({
                    qrCodeType: QrCodeType.TRANSITION_OBJECT,
                    transitionObject: transitionObjectToSend,
                  })}
                />
              </div>

              <div className="asset-transition-page--wrapper--button-wrapper">
                <ButtonComponent
                  title={t("general.buttons.sendMail")}
                  type="button"
                  onClick={() => createAndContinueTransition()}
                />
                <ButtonComponent
                  title={t("general.buttons.cancel")}
                  type="button"
                  onClick={() => history.push("/dashboard")}
                />
              </div>
            </div>
          ) : (
            <LoaderComponent />
          )}
        </PopupComponent>
      </div>
    </LayoutComponent>
  );
};

export default AssetTransitionPage;
