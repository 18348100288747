import { Redirect, Route } from "react-router";
import { isLoginStillValid } from "./crypt/CryptUtils";

const PrivateRoute: React.FC<{
  Page: React.ComponentType<any>;
  path: string;
}> = ({ Page, path }) => {
  return (
    <Route path={path} exact>
      {isLoginStillValid() ? <Page /> : <Redirect to="/login" />}
    </Route>
  );
};

export default PrivateRoute;
