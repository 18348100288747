/**
 * Model of a collection of DisAsset,
 * inspection data and release status.
 */
export interface DisDashboardAssetItem {
  asset: DisAsset;
  inspData: DisEvent[];
  inTransition: boolean;
}

/**
 * Model of an asset from DIS
 */
export interface DisAsset {
  MACHINE_TYPE_ID: number;
  MACHINE_MODEL_ID: number;
  TEST_TYPE_ID: number;
  CLIENT_ID: number;
  MACHINE_ID: number;
  MACHINE_LABEL: string;
  MACHINE_TYPE_NAME: string;
  MACHINE_MODEL_NAME: string;
  MACHINE_NOTICE: string;
  ADDRESS_ID_RESPONSIBLE: number;
  RESPONSIBLE_DISPLAY_NAME: string;
  MACHINE_SERIAL_NUMBER: string;
  ADDRESS_DISPLAY_NAME_OWNER: string;
  ADDRESS_ID_OWNER: number;
  MANUFACTURER_NAME: string;
  IS_QS_INHIBITED: boolean;
  IS_SCRAPPED: boolean;
  MACHINE_INVENTORY_NUMBER: string;
  MACHINE_PRODUCTION_YEAR: number;
  INSP_NEXT_DATE: string;
  INSP_TYPE_NAME: string;
}

/**
 * Event model from DIS Server
 */
export interface DisEvent {
  CLIENT_ID: number;
  INSP_TYPE_ID: number;
  INSP_DATE: string;
  INSP_NEXT_DATE: string;
  INSP_IS_OK: boolean;
  INSP_NOTICE: string;
  INSP_TYPE_NAME: string;
  INSP_TYPE_INTERVAL: number;
  CREATE_TIME: string;
  MAP_INSP_INTERVAL: number;
  IS_ARCHIVED: number;
}

/**
 * Protocol model from DIS Server
 */
export interface DisProtocol {
  CLIENT_ID: number;
  TASK_ID: number;
  TASK_NOTICE: string;
  CREATE_TIME: string;
  EDIT_TIME: string;
  CREATOR_DISPLAY_NAME: string;
  EDITOR_DISPLAY_NAME: string;
}

/**
 * model for {@link DisProtocol} creation
 */
export interface DisProtocolRequest {
  MACHINE_ID: number;
  TASK_NOTICE: string;
  CREATOR_ID: number;
}

/**
 * model response from dis
 */
export interface DisModelResponse {
  CLIENT_ID: number;
  MACHINE_MODEL_ID: number;
  MACHINE_MANUFACTURER_ID: number;
  MACHINE_MODEL_NAME: string;
  MANUFACTURER_NAME: string;
}

/**
 * the model of the types which are needed for creation
 */
export interface DisTypeResponse {
  CLIENT_ID: number;
  MACHINE_TYPE_ID: number;
  MACHINE_TYPE_NAME: string;
}

/**
 * local version of the types for an asset
 */
export interface AssetType {
  id: number;
  name: string;
}

/**
 * Helper to convert a DisType to a local type for better handling
 *
 * @param disType
 * @returns converted object
 */
export const convertDisTypeToAssetType = (
  disType: DisTypeResponse
): AssetType => ({
  id: disType.MACHINE_TYPE_ID,
  name: disType.MACHINE_TYPE_NAME,
});

/**
 * Helper to convert a whole list of types
 *
 * @param disTypes
 * @returns converted list
 */
export const convertDisTypeListToAssetTypeList = (
  disTypes: DisTypeResponse[]
): AssetType[] => {
  let localAssetTypeList: AssetType[] = [];
  disTypes.forEach((disType) =>
    localAssetTypeList.push(convertDisTypeToAssetType(disType))
  );
  return localAssetTypeList;
};

/**
 * the local model of a Model from dis
 */
export interface Model {
  manufacturer: string;
  model: string;
  id: number;
}

/**
 * Helper to convert a DisModel to a local model for better handling
 *
 * @param disModel
 * @returns converted object
 */
export const convertDisModelToModel = (disModel: DisModelResponse): Model => ({
  id: disModel.MACHINE_MODEL_ID,
  manufacturer: disModel.MANUFACTURER_NAME,
  model: disModel.MACHINE_MODEL_NAME,
});

/**
 * Helper to convert a whole list of models
 *
 * @param disTypes
 * @returns converted list
 */
export const convertDisModelListToModelList = (
  disModels: DisModelResponse[]
): Model[] => {
  let localModelList: Model[] = [];
  disModels.forEach((disModel) =>
    localModelList.push(convertDisModelToModel(disModel))
  );
  return localModelList;
};

/**
 * Helper to create a {@link DisProtocolRequest}
 *
 * @param assetId
 * @param creatorId
 * @param text
 * @returns created {@link DisProtocol}
 */
export const createEmptyDisProtocolRequest = (
  assetId: number,
  creatorId: number
): DisProtocolRequest => ({
  CREATOR_ID: creatorId,
  MACHINE_ID: Number(assetId),
  TASK_NOTICE: "",
});

/**
 * Helper to generate an emty {@link DisEvent}
 *
 * @returns empty {@link DisEvent}
 */
export const createEmptyDisEvent = (): DisEvent => ({
  CLIENT_ID: -1,
  CREATE_TIME: "",
  INSP_DATE: "",
  INSP_IS_OK: false,
  INSP_NEXT_DATE: "",
  INSP_NOTICE: "",
  INSP_TYPE_ID: -1,
  INSP_TYPE_INTERVAL: -1,
  INSP_TYPE_NAME: "",
  MAP_INSP_INTERVAL: -1,
  IS_ARCHIVED: 0,
});

/**
 * Model of the intern {@link Asset} for the app
 */
export interface Asset {
  machineSerialNumber: string;
  assetNo: number;
  description: string;
  model: string;
  modelId: number;
  inventoryNo: string;
  location: string;
  locationId: number;
  holderId: number;
  holder: string;
  checkDate: string;
  note: string;
  type: string;
  typeId: number;
  manufacturor: string;
  year: number;
  checkInterval: number;
  annotation: string;
  qsLock: boolean;
  otherValues: string;
  destroyed: boolean;
  testTypeId: number;
  inTransition?: boolean;
  [key: string]: string | boolean | number | undefined;
}

/**
 * Helper to generate an empty {@link Asset}
 *
 * @returns empty asset
 */
export const createEmptyAsset = (): Asset => ({
  assetNo: -1,
  holderId: 0,
  description: "",
  model: "",
  inventoryNo: "",
  location: "",
  holder: "",
  checkDate: "",
  note: "",
  annotation: "",
  checkInterval: 0,
  manufacturor: "",
  otherValues: "",
  qsLock: false,
  destroyed: false,
  type: "",
  year: 1970,
  modelId: -1,
  testTypeId: 1,
  typeId: -1,
  machineSerialNumber: "",
  locationId: -1,
});

/**
 * model of a note for an asset
 */
export interface AssetNote {
  createDate: Date;
  author: string;
  content: string;
}
