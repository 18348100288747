import { AxiosInstance } from "axios";
import { ElementListEntry, ListEntry, Option } from "daume-component-library";
import {
  Asset,
  AssetNote,
  AssetType,
  convertDisModelListToModelList,
  convertDisTypeListToAssetTypeList,
  createEmptyAsset,
  DisDashboardAssetItem,
  DisAsset,
  DisEvent,
  DisProtocol,
  DisProtocolRequest,
  Model,
} from "./Assets.types";
import { ReactComponent as ToolIcon } from "../../assets/icons/tools-icon.svg";
import i18next from "i18next";
// import { ReactComponent as DesktopIcon } from "./assets/icons/desktop-icon.svg";

/**
 * API METHOD - to load the name of a given address id
 *
 * @param axios
 * @param addressId
 * @returns loaded string or ""
 */
export const fetchHolderNameForAssetId = async (
  axios: AxiosInstance,
  addressId: number
): Promise<string> => {
  if (isNaN(addressId)) return "";
  return axios
    .get("/mail/address/id/", { params: { id: addressId } })
    .then((loadedAddressResp) => loadedAddressResp.data)
    .catch((error) => {
      console.error("Error during fetching user with id from DIS!", error);
      return "";
    });
};

/**
 * API METHOD - which creates a new Asset on the server
 * @param axios network instance
 * @param asset asset to be created on dis
 * @returns the new created id or an empty string
 */
export const createAssetOnDis = (
  axios: AxiosInstance,
  asset: Asset
): Promise<boolean> => {
  return axios
    .post("/mail/machine/", convertAssetToDisAsset(asset))
    .then((createAssetResp) => createAssetResp.status === 201)
    .catch((error) => {
      console.error("Error during creating asset on DIS!", error);
      return false;
    });
};

/**
 * API METHOD - to fetch all needed types with their ids
 *
 * @param axios network instance
 * @param allAssetTypes context variable
 * @returns list of found types
 */
export const fetchAllAssetTypes = async (
  axios: AxiosInstance,
  allAssetTypes?: AssetType[]
): Promise<AssetType[]> => {
  if (allAssetTypes?.length !== 0) return allAssetTypes!;
  return axios
    .get("/mail/type/all/")
    .then((loadedAssetTypeResp) =>
      convertDisTypeListToAssetTypeList(loadedAssetTypeResp.data)
    )
    .catch((error) => {
      console.error("Error during fetching types from DIS!", error);
      return [];
    });
};

/**
 * API METHOD - to fetch insp data from sever
 *
 * @param axios netwrok instance
 * @param machineId id of the asset to load
 * @returns loaded DisEvents or an empty array
 */
export const fetchInspDataForAssetId = async (
  axios: AxiosInstance,
  machineId: number
): Promise<DisEvent[]> => {
  return axios
    .get("/mail/machine/insp/id/", { params: { id: machineId } })
    .then((loadedEventResp) => loadedEventResp.data)
    .catch((error) => {
      console.error(
        "Error during fetching insp data for asset with id from DIS!",
        error
      );
      return [];
    });
};

/**
 * API METHOD - loads all Asset from the DIS server
 *
 * @param axios network instance
 * @returns loaded Assets
 */
export const fetchAllAssets = async (
  axios: AxiosInstance
): Promise<Asset[]> => {
  return axios
    .get("/mail/machine/all/")
    .then((loadedMachineResp) =>
      convertDisAssetListToAssetList(loadedMachineResp.data)
    )
    .catch((error) => {
      console.error("Error during fetching assets from DIS!", error);
      return [];
    });
};

/**
 * API METHOD - loads all Asset from the DIS server
 *
 * @param axios network instance
 * @param addressId id of the current user to fetch all assets for
 * @returns loaded Assets
 */
export const fetchAllAssetsForAddressId = async (
  axios: AxiosInstance,
  addressId: number
): Promise<Asset[]> => {
  return axios
    .get("/mail/machine/all/user/", { params: { addressId: addressId } })
    .then((loadedMachineResp) =>
      convertDisAssetListToAssetList(loadedMachineResp.data)
    )
    .catch((error) => {
      console.error(
        "Error during fetching assets from DIS for addressId!",
        error
      );
      return [];
    });
};

/**
 * Helper to fetch collection of DIS Models
 *
 * @param axios network instance
 * @returns converted list of assets
 */
export const fetchAllAssetsWithAdditionalData = async (
  axios: AxiosInstance
): Promise<Asset[]> => {
  return axios
    .get("/mail/machine/all/dashboard/")
    .then((loadedMachineResp) => {
      let convertedAssets: Asset[] = [];
      (loadedMachineResp.data as DisDashboardAssetItem[]).forEach(
        (element: DisDashboardAssetItem) => {
          const newestInspEvent: DisEvent | undefined = getNewestDisEvent(
            element.inspData
          );
          if (newestInspEvent === undefined)
            convertedAssets.push({
              ...convertDisAssetToAsset(element.asset),
              inTransition: !!element.inTransition,
            });
          else {
            convertedAssets.push({
              ...convertDisAssetToAsset(element.asset),
              checkDate: new Date(newestInspEvent.INSP_DATE).toLocaleDateString(
                "de-DE",
                { year: "numeric", month: "2-digit", day: "2-digit" }
              ),
              checkInterval: newestInspEvent.INSP_TYPE_INTERVAL,
              inTransition: !!element.inTransition,
            });
          }
        }
      );
      return convertedAssets;
    })
    .catch((error) => {
      console.error(
        "Error during fetching assets with additional data from DIS!",
        error
      );
      return [];
    });
};

/**
 * Helper to convert a list of {@link DisAsset}s to {@link Asset}s
 *
 * @param disAssets array of dis assets to convert to local data
 * @returns converted asset list
 */
export const convertDisAssetListToAssetList = (
  disAssets: DisAsset[]
): Asset[] => {
  let convertedAssets: Asset[] = [];
  disAssets.forEach((disAsset) =>
    convertedAssets.push(convertDisAssetToAsset(disAsset))
  );
  return convertedAssets;
};

/**
 * Helper to generate a JSX.Element for a location
 *
 * @param asset with the data
 * @returns JSX.Element
 * @tested
 */
export const generateLocationElement = (asset: Asset): JSX.Element => {
  return (
    <>
      {asset.location1 && (
        <span>
          {asset.location1}
          <br />
        </span>
      )}
      {asset.location2 && (
        <span>
          {asset.location2}
          <br />
        </span>
      )}
      {asset.location3 && (
        <span>
          {asset.location3}
          <br />
        </span>
      )}
      {asset.location4 && (
        <span>
          {asset.location4}
          <br />
        </span>
      )}
      {(asset.zipCode || asset.city) && (
        <span>
          {asset.zipCode} {asset.city}
          <br />
        </span>
      )}
    </>
  );
};

/**
 * Helper to convert loaded {@link DisAsset} to an {@link Asset}
 *
 * @param disAsset
 * @returns converted {@link Asset}
 */
export const convertDisAssetToAsset = (disAsset: DisAsset): Asset => ({
  assetNo: disAsset.MACHINE_ID,
  machineSerialNumber: disAsset.MACHINE_SERIAL_NUMBER,
  annotation: disAsset.MACHINE_NOTICE,
  checkDate: disAsset.INSP_NEXT_DATE,
  checkInterval: 0,
  description: disAsset.MACHINE_LABEL,
  destroyed: disAsset.IS_SCRAPPED,
  holder: disAsset.RESPONSIBLE_DISPLAY_NAME,
  holderId: disAsset.ADDRESS_ID_RESPONSIBLE,
  inventoryNo: disAsset.MACHINE_INVENTORY_NUMBER,
  location: disAsset.ADDRESS_DISPLAY_NAME_OWNER,
  manufacturor: disAsset.MANUFACTURER_NAME,
  model: disAsset.MACHINE_MODEL_NAME,
  note: "",
  otherValues: "",
  qsLock: disAsset.IS_QS_INHIBITED,
  type: disAsset.MACHINE_TYPE_NAME,
  year: disAsset.MACHINE_PRODUCTION_YEAR,
  modelId: disAsset.MACHINE_MODEL_ID,
  testTypeId: disAsset.TEST_TYPE_ID,
  typeId: disAsset.MACHINE_TYPE_ID,
  locationId: disAsset.ADDRESS_ID_OWNER,
});

/**
 * Helper to convert a local asset to a uploadable dis asset
 *
 * @param asset
 * @returns converted {@link DisAsset}
 */
export const convertAssetToDisAsset = (asset: Asset): DisAsset => ({
  ADDRESS_ID_RESPONSIBLE: asset.holderId,
  RESPONSIBLE_DISPLAY_NAME: "",
  CLIENT_ID: 0,
  IS_QS_INHIBITED: asset.qsLock,
  IS_SCRAPPED: asset.destroyed,
  MACHINE_ID: asset.assetNo,
  MACHINE_INVENTORY_NUMBER: asset.inventoryNo,
  MACHINE_LABEL: asset.description,
  MACHINE_MODEL_ID: asset.modelId,
  MACHINE_MODEL_NAME: asset.model,
  MACHINE_NOTICE: asset.annotation,
  MACHINE_PRODUCTION_YEAR: asset.year,
  MACHINE_TYPE_ID: asset.typeId,
  MACHINE_TYPE_NAME: asset.type,
  MANUFACTURER_NAME: asset.manufacturor,
  TEST_TYPE_ID: asset.testTypeId,
  INSP_NEXT_DATE: "",
  INSP_TYPE_NAME: "",
  ADDRESS_DISPLAY_NAME_OWNER: "",
  MACHINE_SERIAL_NUMBER: asset.machineSerialNumber,
  ADDRESS_ID_OWNER: asset.locationId,
});

/**
 * API METHOD - to fetch a specific asset by id
 *
 * @param axios
 * @param assetId
 * @returns loaded asset or null
 */
export const fetchAssetById = async (
  axios: AxiosInstance,
  assetId: number
): Promise<Asset> => {
  return axios
    .get("/mail/machine/id/", { params: { id: assetId } })
    .then((loadedAddressResp) => convertDisAssetToAsset(loadedAddressResp.data))
    .catch((error) => {
      console.error("Error during fetching asset with id from DIS!", error);
      return createEmptyAsset();
    });
};

/**
 * API METHOD - to fetch DIS Asset with additional data
 *
 * @param axios
 * @param assetId id of the asset
 * @returns converted asset
 */
export const fetchAssetByIdWithAdditionalData = async (
  axios: AxiosInstance,
  assetId: number
): Promise<Asset> => {
  return axios
    .get("/mail/machine/id/detail/", { params: { id: assetId } })
    .then((loadedAssetResp) => {
      const localWorkingCopy: DisDashboardAssetItem = loadedAssetResp.data;
      const newestInspEvent: DisEvent | undefined = getNewestDisEvent(
        localWorkingCopy.inspData
      );
      if (newestInspEvent === undefined)
        return {
          ...convertDisAssetToAsset(localWorkingCopy.asset),
          inTransition: !!localWorkingCopy.inTransition,
        };
      else
        return {
          ...convertDisAssetToAsset(localWorkingCopy.asset),
          checkDate: new Date(newestInspEvent.INSP_DATE).toLocaleDateString(
            "de-DE",
            { year: "numeric", month: "2-digit", day: "2-digit" }
          ),
          checkInterval: newestInspEvent.MAP_INSP_INTERVAL
            ? newestInspEvent.MAP_INSP_INTERVAL
            : newestInspEvent.INSP_TYPE_INTERVAL
            ? newestInspEvent.INSP_TYPE_INTERVAL
            : 0,
          inTransition: !!localWorkingCopy.inTransition,
        };
    })
    .catch((error) => {
      console.error("Error during fetching asset with id from DIS!", error);
      return createEmptyAsset();
    });
};

/**
 * Helper to generate an options list for a dropdown menu
 *
 * @param modelList full list of models
 * @returns list of model options
 */
export const createModelOptions = (modelList: Model[]): Option[] => {
  let generatedOptionList: Option[] = [];
  modelList.forEach((model) => {
    generatedOptionList.push({
      label: `${model.model}${
        model.manufacturer ? " (" + model.manufacturer + ")" : ""
      }`,
      value: model.id.toString(),
    });
  });
  return generatedOptionList;
};

/**
 * Helper to generate an options list for a dropdown menu
 *
 * @param typeList full list of types
 * @returns list of type options
 */
export const createTypeOptions = (typeList: AssetType[]): Option[] => {
  let generatedOptionList: Option[] = [];
  typeList.forEach((type) => {
    generatedOptionList.push({
      label: type.name,
      value: type.id.toString(),
    });
  });
  return generatedOptionList;
};

/**
 * Helper to generate an options list for a dropdown menu
 *
 * @param axios the axios instance
 * @param allModels context variable
 * @returns all models or an empty array
 */
export const fetchAllModel = async (
  axios: AxiosInstance,
  allModels?: Model[]
): Promise<Model[]> => {
  if (allModels?.length !== 0) return allModels!;
  return axios
    .get("/mail/model/all/")
    .then((modelResp) => convertDisModelListToModelList(modelResp.data))
    .catch((error) => {
      console.error("Error during fetching models  from DIS!", error);
      return [];
    });
};

/**
 * API METHOD - updates an asset on DIS
 *
 * @param axios
 * @param asset
 * @returns true if status code is 200
 */
export const updateAsset = async (
  axios: AxiosInstance,
  asset: Asset
): Promise<boolean> => {
  return axios
    .post("/mail/machine/update/", convertAssetToDisAsset(asset))
    .then((updatedAssetResp) => updatedAssetResp.status === 200)
    .catch((error) => {
      console.error("Error during fetching asset with id from DIS!", error);
      return false;
    });
};

/**
 * Helper to generate List entries for the dashboard page
 *
 * @param assetList
 * @returns generated ElementListEntry list
 */
export const generateAssetDashboardListEntries = (
  assetList: Asset[]
): ElementListEntry[] => {
  let currentListeElementEntries: ElementListEntry[] = [];
  assetList.forEach((asset) =>
    currentListeElementEntries.push({
      id: asset.assetNo.toString(),
      image: <ToolIcon className="placeholder-icon" />,
      label: `${asset.description}${asset.inTransition ? " *" : ""}`,
      value: `${asset.model} ${
        asset.checkDate ? ` (${asset.checkDate} / ${asset.checkInterval})` : ""
      }`,
    })
  );
  return currentListeElementEntries;
};

/**
 * API METHOD - loads all notes for a asset
 *
 * @param axios
 * @param assetId
 * @returns list of all notes or an empty array
 */
export const fetchAllNotesForAssetId = async (
  axios: AxiosInstance,
  assetId: number
): Promise<AssetNote[]> => {
  return axios
    .get("/mail/machine/protocol/id/", { params: { id: assetId } })
    .then((loadedAddressResp) =>
      convertDisProtocolListToAssetNoteList(loadedAddressResp.data)
    )
    .catch((error) => {
      console.error(
        "Error during fetching protocols for an asset with id from DIS!",
        error
      );
      return [];
    });
};

/**
 * API METHOD - to create an protocol on the DIS server
 *
 * @param axios
 * @param protocolRequest
 * @returns boolean if protocol is created
 */
export const createNoteForAssetId = async (
  axios: AxiosInstance,
  protocolRequest: DisProtocolRequest
): Promise<boolean> => {
  return axios
    .post("/mail/machine/protocol/", protocolRequest)
    .then((createProtocolResponse) => createProtocolResponse.status === 201)
    .catch((error) => {
      console.error(
        "Error during creation of protocol for an asset with id from DIS!",
        error
      );
      return false;
    });
};

/**
 * Helper to convert an asset to list entries for the detail page
 *
 * @param asset
 * @param disHolderName
 * @param disEvent
 * @returns generated list entries
 */
export const generateAssetDetailListEntries = (asset: Asset): ListEntry[] => [
  {
    id: "1",
    label: i18next.t("asset.inventoryNo"),
    value: asset.inventoryNo || "-",
  },
  {
    id: "9",
    label: i18next.t("asset.type"),
    value: asset.type || "-",
  },
  {
    id: "2",
    label: i18next.t("asset.assetNo"),
    value: asset.machineSerialNumber || "-",
  },
  {
    id: "3",
    label: i18next.t("asset.description"),
    value: asset.description || "-",
  },
  {
    id: "4",
    label: i18next.t("asset.manufacturor"),
    value: asset.manufacturor || "-",
  },
  {
    id: "10",
    label: i18next.t("asset.model"),
    value: asset.model || "-",
  },
  {
    id: "11",
    label: i18next.t("asset.year"),
    value: asset.year === 0 ? "-" : asset.year.toString(),
  },
  {
    id: "5",
    label: i18next.t("asset.location"),
    value: asset.location || "-",
  },
  {
    id: "6",
    label: i18next.t("asset.holder"),
    value: `${asset.holder || "-"}${asset.inTransition ? " *" : ""}`,
  },
  {
    id: "7",
    label: i18next.t("asset.checkDate"),
    value: asset.checkDate ? asset.checkDate : "-",
  },
  {
    id: "8",
    label: i18next.t("asset.checkInterval"),
    value: asset.checkInterval
      ? `${asset.checkInterval} ${i18next.t("general.months")}`
      : "-",
  },
  {
    id: "12",
    label: i18next.t("asset.annotation"),
    value: asset.annotation || "-",
  },
  {
    id: "13",
    label: i18next.t("asset.qsLock"),
    value: asset.qsLock ? i18next.t("general.yes") : i18next.t("general.no"),
  },
  {
    id: "14",
    label: i18next.t("asset.destroyed"),
    value: asset.destroyed ? i18next.t("general.yes") : i18next.t("general.no"),
  },
  {
    id: "15",
    label: i18next.t("asset.otherValues"),
    value: asset.otherValues || "-",
  },
];

/**
 * Helper to determine which {@link DisEvent} from the server
 * is the newest by CREATE_TIME
 *
 * @param disEvents
 * @returns found {@link DisEvent} or an empty one
 * @tested
 */
export const getNewestDisEvent = (
  disEvents: DisEvent[]
): DisEvent | undefined => {
  if (disEvents.length === 0) return;
  disEvents = disEvents.filter((item) => item.IS_ARCHIVED === 0);
  if (disEvents.length === 0) return;
  let foundIndex: number = 0;
  let newestDate: Date = new Date(disEvents[0].CREATE_TIME);
  disEvents.forEach((disEvent, disEventIndex) => {
    if (newestDate < new Date(disEvent.CREATE_TIME)) {
      newestDate = new Date(disEvent.CREATE_TIME);
      foundIndex = disEventIndex;
    }
  });
  return disEvents[foundIndex];
};

/**
 * Helper to convert {@link DisProtocol} to {@link AssetNote}
 *
 * @param protocol
 * @returns converted {@link AssetNote}
 */
export const convertDisProtocolToAssetNote = (
  protocol: DisProtocol
): AssetNote => {
  const editDate: Date = new Date(protocol.EDIT_TIME);
  return {
    content: protocol.TASK_NOTICE,
    author: protocol.EDITOR_DISPLAY_NAME,
    createDate: editDate,
  };
};

/**
 * Helper to convert a list of {@link DisProtocol}
 *
 * @param protocolList
 * @returns a converted {@link AssetNote} list
 */
export const convertDisProtocolListToAssetNoteList = (
  protocolList: DisProtocol[]
): AssetNote[] => {
  let localAssetNoteList: AssetNote[] = [];
  protocolList.forEach((protocol) =>
    localAssetNoteList.push(convertDisProtocolToAssetNote(protocol))
  );
  return localAssetNoteList;
};

/**
 * Helper to convert a string list to an option list
 *
 * @param list
 * @returns converted options list
 */
export const createOptionsFromStringList = (list: string[]): Option[] => {
  let generatedOptionList: Option[] = [];
  list.forEach((model) =>
    generatedOptionList.push({ label: model, value: model })
  );
  return generatedOptionList;
};

/**
 * Helper to determine if an asset contains a given string
 * @param asset asset to check for search string
 * @param searchString string to check
 * @returns true if search string is included
 * @tested
 */
export const includesAssetGivenSearchString = (
  asset: Asset,
  searchString: string
): boolean =>
  (asset.description || "")
    .toUpperCase()
    .includes(searchString.toUpperCase()) ||
  (asset.model || "").toUpperCase().includes(searchString.toUpperCase()) ||
  (asset.type || "").toUpperCase().includes(searchString.toUpperCase()) ||
  (asset.inventoryNo || "")
    .toUpperCase()
    .includes(searchString.toUpperCase()) ||
  (asset.annotation || "").toUpperCase().includes(searchString.toUpperCase()) ||
  (asset.manufacturor || "")
    .toUpperCase()
    .includes(searchString.toUpperCase()) ||
  (asset.note || "").toUpperCase().includes(searchString.toUpperCase());
