import {
  ButtonComponent,
  DropdownComponent,
  LayoutComponent,
  LoaderComponent,
  Option,
  PopupComponent,
} from "daume-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import "../styles/AssetTransitionStyles.scss";
import { Asset, createEmptyAsset } from "../utils/assets/Assets.types";
import { fetchAssetById } from "../utils/assets/AssetsUtils";
import { useAxios } from "../utils/AxiosUtil";
import {
  createEmptyTransitionObject,
  TransitionObject,
} from "../utils/transition/Transition.types";
import { createTransitionObjectAndSendMail } from "../utils/transition/TransitionUtils";
import { Address } from "../utils/user/User.types";
import { createUserOptions, fetchAllUsers } from "../utils/user/UserUtils";
import { DisDataContext } from "./App";

interface AssetTransitionQrCodePageProps {}

const AssetTransitionMailPage: React.FC<
  AssetTransitionQrCodePageProps
> = () => {
  const { setAllUsers, allUsers } = useContext(DisDataContext);
  const location = useLocation<{ transitionObject: TransitionObject }>();
  const [assetToEdit, setAssetToEdit] = useState<Asset>(createEmptyAsset());
  const [transitionObjectToSend, setTransitionObjectToSend] =
    useState<TransitionObject>(createEmptyTransitionObject());
  const history = useHistory();
  const { t } = useTranslation();
  const axios = useAxios();
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [localOptionLoadedUsers, setLocalOptionLoadedUsers] = useState<
    Option[]
  >([]);
  const [localLoadedUsers, setLocalLoadedUsers] = useState<Address[]>([]);

  /**
   * fills local data for further working
   */
  useEffect(() => {
    if (location.state?.transitionObject && !!axios) {
      setTransitionObjectToSend(location.state.transitionObject);
      Promise.all([
        fetchAllUsers(axios, allUsers),
        fetchAssetById(axios, location.state.transitionObject.assetId),
      ]).then(([loadedUsers, loadedAsset]) => {
        setAllUsers(loadedUsers);
        setLocalLoadedUsers(loadedUsers);
        setLocalOptionLoadedUsers(createUserOptions(loadedUsers, true));
        setAssetToEdit(loadedAsset);
      });
    }
    // eslint-disable-next-line
  }, [location, axios]);

  /**
   * calls the api for creating a transitionobject and sending a mail
   * to the new holder. Redirects to dashboard if succesfull
   */
  const sendTransitionMail = (): void => {
    toggleIsLoading(true);
    createTransitionObjectAndSendMail(transitionObjectToSend, axios).then(
      (success) => {
        if (success) history.push("/dashboard");
        console.error("error during transition object creation");
        toggleIsLoading(false);
      }
    );
  };

  return (
    <LayoutComponent>
      <div className="asset-transition-qr-page--popup-wrapper">
        <PopupComponent
          closeFunction={() => history.push("/dashboard")}
          showClose
        >
          <div className={"asset-transition-qr-page--wrapper"}>
            {localOptionLoadedUsers.length > 0 ? (
              <div className="asset-transition-page--wrapper--text-wrapper">
                {t("transitionPage.assetMailText", {
                  replace: { assetLabel: assetToEdit.description },
                })}
                <DropdownComponent
                  searchable
                  selectedOption={
                    transitionObjectToSend.addressIdTo
                      ? transitionObjectToSend.addressIdTo.toString()
                      : ""
                  }
                  required
                  placeholder={t("transitionPage.dropDownPlaceholder")}
                  options={localOptionLoadedUsers}
                  onChange={(value) =>
                    setTransitionObjectToSend({
                      ...transitionObjectToSend,
                      addressIdTo: Number(value),
                      mail:
                        localLoadedUsers.filter(
                          (users) => users.ADDRESS_ID === Number(value)
                        )[0].ADDRESS_EMAIL || "",
                    })
                  }
                />
              </div>
            ) : (
              <LoaderComponent />
            )}

            <div className="asset-transition-qr-page--wrapper--button-wrapper">
              {localLoadedUsers.length > 0 && (
                <ButtonComponent
                  isLoading={isLoading}
                  disabled={isLoading}
                  title={t("general.buttons.sendMail")}
                  type="button"
                  onClick={() => sendTransitionMail()}
                />
              )}
              <ButtonComponent
                disabled={isLoading}
                title={t("general.buttons.cancel")}
                type="button"
                onClick={() => history.push("/dashboard")}
              />
            </div>
          </div>
        </PopupComponent>
      </div>
    </LayoutComponent>
  );
};

export default AssetTransitionMailPage;
