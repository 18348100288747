import {
  ButtonComponent,
  ElementListComponent,
  PopupComponent,
  LayoutComponent,
  ScannerComponent,
  InputComponent,
  DropdownComponent,
  LoaderComponent,
  Option,
  CheckboxComponent,
} from "daume-component-library";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../assets/icons/plus-icon.svg";
import { ReactComponent as QrIcon } from "../assets/icons/qr-icon.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/search-icon.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/close-icon.svg";
import { ReactComponent as UserIcon } from "../assets/icons/user.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { useHistory } from "react-router-dom";
import "../styles/DashboardPageStyles.scss";
import {
  createOptionsFromStringList,
  generateAssetDashboardListEntries,
  fetchAllAssetsForAddressId,
  fetchAllAssets,
  includesAssetGivenSearchString,
} from "../utils/assets/AssetsUtils";
import {
  QrCodeContent,
  QrCodeType,
} from "../utils/transition/Transition.types";
import { useAxios } from "../utils/AxiosUtil";
import { Asset } from "../utils/assets/Assets.types";
import { decryptString } from "../utils/crypt/CryptUtils";
import {
  isUserAdmin,
  isUserManager,
  logoutUser,
} from "../utils/user/UserUtils";
import { SearchContext } from "./App";

interface DashboardPageProps {}

const DashboardPage: React.FC<DashboardPageProps> = () => {
  const {
    modelSearchString,
    searchString,
    setModelSearchString,
    setSearchString,
    setTypeSearchString,
    holderSearchString,
    locationSearchString,
    setHolderSearchString,
    setLocationSearchString,
    typeSearchString,
    isDueInTwoWeeks,
    isOverdue,
    toggleDueInTwoWeeks,
    toggleOverdue,
    isFilterOwnAssets,
    setDateSearchDate,
    toggleFilterOwnAssets,
    dateSearchDate,
  } = useContext(SearchContext);
  const history = useHistory();
  const { t } = useTranslation();
  const axios = useAxios();
  const isAdmin: boolean = isUserAdmin();
  const isManager: boolean = isUserManager();
  const currentAddressId: number = parseInt(
    decryptString(
      localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_ADDRESS_ID!)!
    )
  );

  const [showFilter, toggleFilter] = useState<boolean>(false);
  const [showScannerPopup, toggleScannerPopup] = useState<boolean>(false);
  const [notFilteredData, setNotFilteredData] = useState<Asset[]>([]);
  const [filteredData, setFilteredData] = useState<Asset[]>([]);
  const [isLoading, toggleLoading] = useState<boolean>(true);
  const [modelOptions, setModelOptions] = useState<Option[]>([]);
  const [typeOptions, setTypeOptions] = useState<Option[]>([]);
  const [locationOptions, setLocationOptions] = useState<Option[]>([]);
  const [holderOptions, setHolderOptions] = useState<Option[]>([]);

  /**
   * This useEffect filters a given asset list by parameter
   */
  useEffect(() => {
    let localFilteredAsset: Asset[] = notFilteredData;
    if (isFilterOwnAssets)
      localFilteredAsset = localFilteredAsset.filter(
        (asset) =>
          asset.holderId ===
          parseInt(
            decryptString(
              localStorage.getItem(
                process.env.REACT_APP_LOCALSTORAGE_ADDRESS_ID!
              )!
            )
          )
      );
    if (isOverdue || isDueInTwoWeeks) {
      localFilteredAsset = localFilteredAsset.filter((asset) => {
        if (!asset.checkDate) return false;
        const isAssetOverdue: boolean = moment(
          asset.checkDate,
          "DD.MM.YYYY"
        ).isBefore(moment.now());

        const isAssetDueInTwoWeeks: boolean = moment()
          .add(2, "weeks")
          .isSameOrAfter(moment(asset.checkDate, "DD.MM.YYYY"));

        if (isOverdue && isDueInTwoWeeks) {
          return isAssetDueInTwoWeeks || isAssetOverdue;
        }
        if (isOverdue) {
          return isAssetOverdue;
        }
        if (isDueInTwoWeeks) {
          return isAssetDueInTwoWeeks && !isAssetOverdue;
        }
        return false;
      });
    }
    if (dateSearchDate)
      localFilteredAsset = localFilteredAsset.filter((asset) => {
        return moment(asset.checkDate, "DD.MM.YYYY").isSame(
          moment(dateSearchDate),
          "day"
        );
      });
    if (modelSearchString)
      localFilteredAsset = localFilteredAsset.filter(
        (asset) =>
          asset.model?.toUpperCase() === modelSearchString.toUpperCase()
      );
    if (typeSearchString)
      localFilteredAsset = localFilteredAsset.filter(
        (asset) => asset.type?.toUpperCase() === typeSearchString.toUpperCase()
      );
    if (holderSearchString)
      localFilteredAsset = localFilteredAsset.filter(
        (asset) =>
          asset.holder?.toUpperCase() === holderSearchString.toUpperCase()
      );
    if (locationSearchString)
      localFilteredAsset = localFilteredAsset.filter(
        (asset) =>
          asset.location?.toUpperCase() === locationSearchString.toUpperCase()
      );
    if (searchString) {
      localFilteredAsset = localFilteredAsset.filter((asset) =>
        includesAssetGivenSearchString(asset, searchString)
      );
    }
    setFilteredData([...localFilteredAsset]);
    // eslint-disable-next-line
  }, [
    searchString,
    notFilteredData,
    modelSearchString,
    typeSearchString,
    holderSearchString,
    locationSearchString,
    isOverdue,
    isDueInTwoWeeks,
    dateSearchDate,
    isFilterOwnAssets,
  ]);

  /**
   * set initially a not filterd list with all loaded assets
   */
  useEffect(() => {
    if (!!axios && notFilteredData.length === 0) {
      if (isAdmin) {
        fetchAllAssets(axios).then((convertedAssets) => {
          setNotFilteredData(convertedAssets);
          generateDropDownEntries(convertedAssets);
          toggleLoading(false);
        });
      } else
        fetchAllAssetsForAddressId(axios, currentAddressId).then(
          (convertedAssets) => {
            setNotFilteredData(convertedAssets);
            generateDropDownEntries(convertedAssets);
            toggleLoading(false);
          }
        );
    }
    // eslint-disable-next-line
  }, [axios]);

  /**
   * Helper to generate all needed option lists
   *
   * @param assetList
   */
  const generateDropDownEntries = (assetList: Asset[]): void => {
    let modelList: string[] = [];
    let typeList: string[] = [];
    let locationList: string[] = [];
    let holderList: string[] = [];

    assetList.forEach((asset) => {
      const addModelStringToList: boolean =
        !!asset.model && !modelList.includes(asset.model);
      const addTypeStringToList: boolean =
        !!asset.type && !typeList.includes(asset.type);
      const addLocationStringToList: boolean =
        !!asset.location && !locationList.includes(asset.location);
      const addHolderStringToList: boolean =
        !!asset.holder && !holderList.includes(asset.holder);

      if (addModelStringToList) modelList.push(asset.model);
      if (addTypeStringToList) typeList.push(asset.type);
      if (addLocationStringToList) locationList.push(asset.location);
      if (addHolderStringToList) holderList.push(asset.holder);
    });

    setModelOptions(createOptionsFromStringList(modelList));
    setTypeOptions(createOptionsFromStringList(typeList));
    setLocationOptions(createOptionsFromStringList(locationList));
    setHolderOptions(createOptionsFromStringList(holderList));
  };

  /**
   * Helper to handle a found qr code scan result
   *
   * @param scanResult
   */
  const handleQrScan = (scanResult: string): void => {
    const foundContent: QrCodeContent = JSON.parse(scanResult);
    switch (foundContent.qrCodeType) {
      case QrCodeType.ASSET:
        history.push("/detail", { assetId: foundContent.assetId });
        break;
      case QrCodeType.TRANSITION_OBJECT:
      case QrCodeType.TRANSITION_OBJECT_TAKEOVER:
        history.push("/transition/decision", {
          transitionObject: foundContent.transitionObject,
          type: foundContent.qrCodeType,
        });
        break;
    }
  };

  return (
    <LayoutComponent>
      {showFilter || (
        <div onClick={() => logoutUser(history)} className="logout-button">
          <LogoutIcon />
        </div>
      )}
      <div className={"dashboard-page--wrapper"}>
        {showFilter ? (
          <div className={"dashboard-page--search-wrapper"}>
            <CloseIcon
              className="close-icon"
              onClick={() => toggleFilter(false)}
            />
            <h1>{t("dashboardPage.assetSearch")}</h1>
            <InputComponent
              onChange={setSearchString}
              placeholder={t("general.search")}
              value={searchString}
            />
            <DropdownComponent
              searchable
              placeholder={t("dashboardPage.search.model")}
              options={modelOptions}
              onChange={(value) => setModelSearchString(value)}
              selectedOption={modelSearchString!}
            />
            <DropdownComponent
              searchable
              placeholder={t("dashboardPage.search.type")}
              options={typeOptions}
              onChange={(value) => setTypeSearchString(value)}
              selectedOption={typeSearchString!}
            />
            <DropdownComponent
              searchable
              placeholder={t("dashboardPage.search.location")}
              options={locationOptions}
              onChange={(value) => setLocationSearchString(value)}
              selectedOption={locationSearchString!}
            />
            <DropdownComponent
              searchable
              placeholder={t("dashboardPage.search.holder")}
              options={holderOptions}
              onChange={(value) => setHolderSearchString(value)}
              selectedOption={holderSearchString!}
            />
            <InputComponent
              onChange={(value) => setDateSearchDate(new Date(value))}
              value={dateSearchDate?.toISOString().split("T")[0] || ""}
              placeholder={t("dashboardPage.search.searchCheckDate")}
              type="date"
            />
            <div className={"dashboard-page--search-wrapper--checkdate-box"}>
              <CheckboxComponent
                checked={isOverdue}
                onCheck={() => toggleOverdue(!isOverdue)}
                value={t("dashboardPage.search.overDue")}
              />
              <CheckboxComponent
                checked={isDueInTwoWeeks}
                onCheck={() => toggleDueInTwoWeeks(!isDueInTwoWeeks)}
                value={t("dashboardPage.search.dueInTwoWeeks")}
              />
            </div>
            <div
              className={"dashboard-page--search-wrapper--reset-user-wrapper"}
            >
              <ButtonComponent
                title="Reset"
                type="button"
                onClick={() => {
                  toggleOverdue(false);
                  toggleDueInTwoWeeks(false);
                  toggleFilterOwnAssets(false);
                  setSearchString("");
                  setModelSearchString("");
                  setTypeSearchString("");
                  setLocationSearchString("");
                  setDateSearchDate(undefined);
                  setHolderSearchString("");
                }}
              />
              <UserIcon
                className={[
                  "dashboard-page--search-wrapper--reset-user-wrapper-icon",
                  isFilterOwnAssets
                    ? "dashboard-page--search-wrapper--reset-user-wrapper-icon-selected"
                    : undefined,
                ].join(" ")}
                onClick={() => toggleFilterOwnAssets(!isFilterOwnAssets)}
              />
            </div>
          </div>
        ) : (
          <div className={"dashboard-page--title-wrapper"}>
            <h1>{t("dashboardPage.assets")}</h1>
            <small>{t("dashboardPage.overviewText")}</small>
            <div className="dashboard-page--searchbuttons">
              {(isAdmin || isManager) && (
                <ButtonComponent
                  title={<PlusIcon />}
                  onClick={() => history.push("/create")}
                />
              )}
              <ButtonComponent
                title={<QrIcon />}
                onClick={() => toggleScannerPopup(true)}
              />
              <ButtonComponent
                disabled={isLoading}
                title={<SearchIcon />}
                onClick={() => toggleFilter(true)}
              />
            </div>
          </div>
        )}
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <ElementListComponent
            key={`element-list-of-filtered-asset-${filteredData.length}`}
            list={generateAssetDashboardListEntries(filteredData)}
            onClick={(assetId) => history.push("/detail", { assetId: assetId })}
          />
        )}

        {showScannerPopup && (
          <PopupComponent
            closeFunction={() => toggleScannerPopup(false)}
            showClose
          >
            <div className={"dashboard-page--popup"}>
              <h1>{t("dashboardPage.scanQrTitle")}</h1>
              <small>{t("dashboardPage.scanQrHelper")}</small>
              <ScannerComponent
                onError={console.error}
                onScan={(scanResult) => handleQrScan(scanResult)}
              />
              <ButtonComponent
                title={t("general.buttons.cancel")}
                onClick={() => toggleScannerPopup(false)}
              />
            </div>
          </PopupComponent>
        )}
      </div>
    </LayoutComponent>
  );
};

export default DashboardPage;
