import {
  ButtonComponent,
  LayoutComponent,
  PopupComponent,
} from "daume-component-library";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../styles/AssetTransitionStyles.scss";
import { Asset, createEmptyAsset } from "../utils/assets/Assets.types";
import { fetchAssetById } from "../utils/assets/AssetsUtils";
import { useAxios } from "../utils/AxiosUtil";
import {
  createEmptyTransitionObject,
  QrCodeType,
  TransitionObject,
} from "../utils/transition/Transition.types";
import "../styles/AssetTakeoverPageStyles.scss";
import { useTranslation } from "react-i18next";
import { createTransitionObjectAndSendMail } from "../utils/transition/TransitionUtils";
import QRCode from "react-qr-code";
import { decryptString } from "../utils/crypt/CryptUtils";

interface AssetTakeoverPageProps {}

const AssetTakeoverPage: React.FC<AssetTakeoverPageProps> = () => {
  const location = useLocation<{ assetId: number }>();
  // eslint-disable-next-line
  const [assetToEdit, setAssetToEdit] = useState<Asset>(createEmptyAsset());
  const history = useHistory();
  const axios = useAxios();
  const [transitionObjectToSend, setTransitionObjectToSend] =
    useState<TransitionObject>();
  const [currentHolderName, setCurrentHolderName] = useState<string>("");
  const { t } = useTranslation();
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [isError, toggleError] = useState<boolean>(false);
  const currentAddressId: number = parseInt(
    decryptString(
      localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_ADDRESS_ID!)!
    )
  );

  /**
   * sets local data for further working
   */
  useEffect(() => {
    if (location.state?.assetId && !!axios) {
      fetchAssetById(axios, location.state.assetId).then((loadedAsset) => {
        setAssetToEdit(loadedAsset);
        setTransitionObjectToSend({
          ...createEmptyTransitionObject(),
          assetId: location.state.assetId,
          addressIdFrom: loadedAsset.holderId,
          addressIdTo: currentAddressId,
        });
        setCurrentHolderName(loadedAsset.holder);
      });
    }
    // eslint-disable-next-line
  }, [location, axios]);

  // Method to check if a transition object is ok and
  // to create it on the server
  const createTransitionObjectForTakeover = (): void => {
    // TODO Notification
    if (!transitionObjectToSend) return;
    toggleIsLoading(true);
    createTransitionObjectAndSendMail(transitionObjectToSend, axios).then(
      (success) => {
        if (success) history.push("/dashboard");
        else toggleError(true);
        console.error("error during transition object creation");
        toggleIsLoading(false);
      }
    );
  };

  return (
    <LayoutComponent>
      <div className="asset-transition-page--popup-wrapper">
        <PopupComponent
          closeFunction={() => history.push("/dashboard")}
          showClose
        >
          <div className="takeover-page-wrapper">
            <QRCode
              value={JSON.stringify({
                qrCodeType: QrCodeType.TRANSITION_OBJECT_TAKEOVER,
                transitionObject: transitionObjectToSend,
              })}
            />

            <div className="takeover-page-wrapper--text-wrapper">
              <p>{t("takeoverPage.text")}</p>
              <p className="highlight-para">{assetToEdit.description || "-"}</p>
              <p>{t("takeoverPage.from")}</p>
              <p className="highlight-para">{currentHolderName || "-"}</p>
              <p>{t("takeoverPage.to")}</p>
              <p className="highlight-para">
                {decryptString(
                  localStorage.getItem(
                    process.env.REACT_APP_LOCALSTORAGE_DISPLAY_NAME!
                  )!
                )}
              </p>
            </div>

            {isError && (
              <div className="takeover-page-wrapper--error-wrapper">
                {t("takeoverPage.error")}
              </div>
            )}

            <div className="takeover-page-wrapper--button-wrapper">
              <ButtonComponent
                title={t("general.buttons.sendMail")}
                type="button"
                onClick={() => createTransitionObjectForTakeover()}
                isLoading={isLoading}
                disabled={isLoading}
              />
              <ButtonComponent
                title={t("general.buttons.cancel")}
                type="button"
                onClick={() => history.push("/dashboard")}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          </div>
        </PopupComponent>
      </div>
    </LayoutComponent>
  );
};

export default AssetTakeoverPage;
