/**
 * State of an transition object
 */
export enum TransitionState {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

/**
 * types of possible qr codes
 */
export enum QrCodeType {
  ASSET = "ASSET",
  TRANSITION_OBJECT = "TRANSITION_OBJECT",
  TRANSITION_OBJECT_TAKEOVER = "TRANSITION_OBJECT_TAKEOVER",
}

/**
 * Model of a TransitionObject
 */
export interface TransitionObject {
  id?: string;
  createDate?: Date;
  addressIdFrom: number;
  addressIdTo: number;
  assetId: number;
  state: TransitionState;
  mail?: string;
  appUserId?: number;
}

/**
 * Helper to create an empty TransitionObject
 *
 * @returns empty TransitionObject
 */
export const createEmptyTransitionObject = (): TransitionObject => ({
  addressIdFrom: 0,
  addressIdTo: 0,
  mail: "",
  assetId: 0,
  state: TransitionState.PENDING,
});

/**
 * Model of a possible content in a qr code
 */
export interface QrCodeContent {
  assetId?: string;
  transitionObject?: TransitionObject;
  qrCodeType: QrCodeType;
}
